import React from 'react';

import { getHeadingLevel } from './content';
import useHasMounted from 'context/useHasMounted';
import SanitizeHtml from './SanitizeHtml';
import ContentfulKomponentToppbanner from 'parts/top-banner/TopBanner';
import ContentfulKomponentTekstMedOverskrift from 'parts/title-and-text/TitleAndText';
import ContentfulKomponentBildeOgInnhold from 'parts/image-and-content/ImageAndContent';
import ContentfulKomponentInnhold from 'parts/basic-content/BasicContent';
import ContentfulKomponentRutenettelementer from 'parts/content-grid-elements/ContentGridElements';
import ContentfulKomponentRelatertInnhold from 'parts/related-content/RelatedContent';
import ContentfulKomponentSammenligningAvProdukter from 'parts/product-columns/ProductColumns';
import ContentfulKomponentProduktEr from 'parts/product-grid/ProductGrid';
import ContentfulKomponentLedigeStillinger from 'parts/job-vacancies/JobVacancies';
import ContentfulKomponentFiberutbyggingListe from 'parts/fiber-development/FiberDevelopment';
import ContentfulKomponentAccordion from 'parts/accordion/Accordion';
import ContentfulKomponentScrollytelling from 'parts/scrolly-telling/ScrollyTelling';
import ContentfulKomponentBilde from 'parts/image/Image';
import ContentfulKomponentKnapp from 'components/forms/Button';
import ContentfulKomponentPersonEr from 'parts/persons/persons';
import ContentfulKomponentSkjema from 'parts/form/Form';
import ContentfulKomponentVideo from 'parts/video/video';
import ContentfulKomponentStatistikk from 'parts/stats/Stats';
import ContentfulKomponentSkreddersydd from 'parts/miscellaneous/Miscellaneous';
import StickyContent from 'parts/sticky-content/StickyContent';

const components = {
	ContentfulKomponentToppbanner,
	ContentfulKomponentTekstMedOverskrift,
	ContentfulKomponentBildeOgInnhold,
	ContentfulKomponentInnhold,
	ContentfulKomponentRutenettelementer,
	ContentfulKomponentRelatertInnhold,
	ContentfulKomponentSammenligningAvProdukter,
	ContentfulKomponentProduktEr,
	ContentfulKomponentLedigeStillinger,
	ContentfulKomponentFiberutbyggingListe,
	ContentfulKomponentAccordion,
	ContentfulKomponentScrollytelling,
	ContentfulKomponentBilde,
	ContentfulKomponentKnapp,
	ContentfulKomponentPersonEr,
	ContentfulKomponentSkjema,
	ContentfulKomponentVideo,
	ContentfulKomponentStatistikk,
	ContentfulKomponentSkreddersydd,
	ContentfulKomponentStickyBilderOgTekst: StickyContent,
};

/**
 * Retrieves the rendered HTML content of a specified component element.
 *
 * This function searches for an HTML element with the provided `componentName`
 * as its ID attribute in the current browser window's document.
 *
 * @param {string} componentName - The ID of the component element to retrieve content from.
 * @returns {string} The innerHTML of the component element or an empty string if the element is not found.
 */
const getRenderedContent = componentName => {
	if (typeof window === 'undefined') return '';
	const element = window.document.querySelector(`#${componentName}`);
	return element ? element.innerHTML : '';
};

/**
 * Retrieves and renders a component based on its name and provided props.
 *
 * @param {Object} props - The props object for the GetComponent function.
 * @param {string} props.componentName - The name of the component to render.
 * @param {string} props.location - The location context of the component.
 * @param {string} [props.headinglevel='h2'] - The heading level for the component.
 * @param {number} props.componentindex - The index of the component within its context.
 * @param {string} [props.transitions='false'] - Determines whether CSS transitions are enabled.
 * @param {Object} props.pagesettings - The settings for the page containing the component.
 * @returns {JSX.Element|null} The rendered component JSX element or null if conditions are not met.
 */
export default function GetComponent({
	componentName,
	location,
	headinglevel = 'h2',
	transitions = 'false',
	pagesettings,
	...props
}) {
	// Check if the component has mounted before rendering
	const hasMounted = useHasMounted();
	if (!hasMounted) return null;

	// If componentName is invalid or not found in components object
	if (!componentName || !components[componentName]) {
		return (
			<SanitizeHtml
				html={getRenderedContent(
					`${componentName}-${props?.block?.id}`
				)}
			/>
		);
	}

	const Component = components[componentName];

	const isfirstheading =
		props.isfirstheading !== undefined
			? props.isfirstheading
			: props?.componentindex === 0;

	return (
		<Component
			{...props}
			location={location}
			headinglevel={headinglevel}
			isfirstheading={isfirstheading}
			pagesettings={pagesettings}
			transitions={transitions}
		/>
	);
}

/**
 * Set properties for a component.
 *
 * @param {Object} component - The component to set properties for.
 * @param {Array} allComponents - The array of components.
 * @param {number} index - The index of the component in the array.
 */
export function setComponentProps(component, allComponents, index) {
	if (!component) return;

	// Set component index

	component.componentindex = index;

	// Set component name
	component.componentName = component?.__typename;

	// Determine heading level based on if is first component or not
	component.headinglevel = getHeadingLevel(true, index, {
		innholdsblokker: allComponents,
	});

	// Determine if is the last component in the array or not
	component.islastcomponent =
		allComponents?.length === index + 1 ? 'true' : 'false';

	// Get the previous component in the array
	component.prevcomponent = allComponents[index - 1];

	// Determine className based on image-position
	component.sectionclass =
		component?.bildePosisjon === 'Fullbredde med innhold til venstre'
			? `${component?.__typename} fullwidth`
			: component?.__typename;

	return component;
}

/**
 * Component for rendering a hidden page title based on provided settings and conditions.
 *
 * @param {Object} props - The props object for the HiddenPageTitle component.
 * @param {number} props.index - The index of the component within the page.
 * @param {Object} props.componentSettings - Settings specific to the component.
 * @param {Object} props.pagesettings - Settings specific to the page.
 * @param {Object} props.page - The page object containing page data.
 * @returns {JSX.Element|null} The hidden page title element or null if conditions are not met.
 */
export function HiddenPageTitle({
	index,
	componentSettings,
	pagesettings,
	page,
}) {
	/**
	 * Render null if the component index is not the first.
	 */
	if (index !== 0) return null;

	/**
	 * Check if the title should be hidden based on component or page settings.
	 * Render null if title should be hidden.
	 */
	if (
		componentSettings?.hidetitle === undefined ||
		!pagesettings?.hidepagetitle
	)
		return null;

	/**
	 * Render a hidden page title element.
	 *
	 * @type {JSX.Element}
	 */
	return <h1 className="screen-reader-text">{page?.tittel}</h1>;
}
