import { useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';

import { trackBackEnd } from './AnalyticsProvider';

const prefix = 'nte-sms-';
export const tokenKey = prefix + 'token-data';

export default function useSmsAuth() {
	const token = useRef(
		typeof window !== 'undefined' && localStorage.getItem(tokenKey)
	);
	const phone = useRef(null);

	useEffect(() => {
		try {
			const tokenData = localStorage.getItem(tokenKey);

			if ((!tokenData || tokenData === 'null') && !token.current) {
				token.current = null;
			}

			const parsedToken = parseJWT(tokenData);

			const isExpired = !parsedToken || parsedToken?.exp < dayjs().unix();

			if (parsedToken?.phone) {
				phone.current = parsedToken.phone;
			}

			if (!isExpired && tokenData) {
				token.current = tokenData;

				return;
			} else {
				localStorage.removeItem(tokenKey);

				token.current = null;
			}
		} catch (error) {
			console.log(error);
		}

		//eslint-disable-next-line
	}, []);

	return {
		isAuthenticated: !!token.current,
		token: token.current,
		phone: phone.current,
		setToken: value => {
			localStorage.setItem(tokenKey, value);
			window.location.reload();
		},
		logout: ({ redirect, entry }) => {
			if (token?.current && entry) {
				const parsedToken = parseJWT(token?.current);

				trackBackEnd('User Signed Out', {
					entry,
					method: 'SMS',
					phone: parsedToken?.phone || '',
				});
			}

			localStorage.removeItem(tokenKey);
			token.current = null;

			if (redirect) {
				navigate(redirect);
			}
		},
	};
}

export function parseJWT(token) {
	if (!token) return null;
	return JSON.parse(window.atob(token.split('.')[1]));
}
