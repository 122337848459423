import React from 'react';

export default function Meta(props) {
	let updatedTime = props?.dateModified || props?.datePublished;
	if (updatedTime)
		updatedTime = Math.floor(new Date(updatedTime).getTime() / 1000);

	return (
		<>
			<title key={`meta-title-${props?.title}`} id="meta-title">
				{props?.title ? props?.title + ' - NTE' : 'NTE'}
			</title>
			<meta
				name="title"
				property="og:title"
				content={props?.title ? props?.title + ' - NTE' : 'NTE'}
			/>
			<meta
				key="meta-description"
				id="meta-description"
				name="description"
				content={props?.description}
			/>
			<meta
				property="og:url"
				content={
					(props?.location?.pathname &&
						`https://nte.no${props?.location?.pathname}`) ||
					'https://nte.no'
				}
			/>
			<meta
				key="meta-theme-color"
				id="meta-theme-color"
				name="theme-color"
				content="#0079AD"
			/>
			<meta
				key="meta-fb-sitename"
				id="meta-fb-sitename"
				property="og:site_name"
				content="NTE AS"
			/>
			<meta
				key="meta-fb-locale"
				id="meta-fb-locale"
				property="og:locale"
				content="nb_no"
			/>
			<meta
				key="meta-fb-title"
				id="meta-fb-title"
				property="og:title"
				content={props?.title}
			/>
			<meta
				key="meta-fb-description"
				id="meta-fb-description"
				property="og:description"
				content={props?.description}
			/>
			<meta
				key="meta-fb-image"
				id="meta-fb-image"
				property="og:image"
				itemProp="image"
				content={
					(props?.image?.url &&
						encodeURI(
							`${props?.image?.url}?w=1200&h=675&fit=thumb&fm=jpg`
						)) ||
					'https://nte.no/nte-seo-image.jpg'
				}
			/>
			{updatedTime && (
				<meta
					key="meta-fb-updated-time"
					id="meta-fb-updated-time"
					property="og:updated_time"
					itemProp="updatedtime"
					content={updatedTime}
				/>
			)}
			<meta
				property="fb:app_id"
				content={process.env.GATSBY_FACEBOOK_APP_ID}
			/>

			<meta
				key="meta-fb-img-width"
				id="meta-fb-img-width"
				property="og:image:width"
				content={props?.image?.width || '1200'}
			/>
			<meta
				key="meta-fb-img-height"
				id="meta-fb-img-height"
				property="og:image:height"
				content={props?.image?.height || '675'}
			/>
			<meta
				key="meta-fb-type"
				id="meta-fb-type"
				property="og:type"
				content="website"
			/>
			<meta
				key="meta-tw-title"
				id="meta-tw-title"
				name="twitter:title"
				content={props?.title}
			/>
			<meta
				key="meta-tw-description"
				id="meta-tw-description"
				name="twitter:description"
				content={props?.description}
			/>
			<meta
				key="meta-tw-image"
				id="meta-tw-image"
				name="twitter:image"
				content={
					(props?.image?.url &&
						`${props?.image?.url}?w=1200&h=675&fit=thumb&fm=jpg`) ||
					'https://nte.no/nte-seo-image.jpg'
				}
			/>
		</>
	);
}

export function PreventIndexing({
	directives = { noindex: true, nofollow: true },
}) {
	if (!directives) return null;

	const content = [];
	if (directives?.noindex) content.push('noindex');
	if (directives?.nofollow) content.push('nofollow');

	if (!content.length > 0) return null;

	return (
		<>
			<meta
				key="meta-robots"
				id="meta-robots"
				name="robots"
				content={content.join(', ')}
			/>
			<meta
				key="meta-googlebot"
				id="meta-googlebot"
				name="googlebot"
				content={content.join(', ')}
			/>
		</>
	);
}

export function setCrawlingDirectives(crawlSettings) {
	let directives = {},
		preventCrawl;
	if (crawlSettings === null) {
		directives = { noindex: true, nofollow: true };
	} else if (crawlSettings?.forhindreIndeksering !== null) {
		preventCrawl = crawlSettings?.forhindreIndeksering?.length
			? crawlSettings?.forhindreIndeksering
			: [];
		directives = {
			noindex: preventCrawl?.includes('noindex'),
			nofollow: preventCrawl?.includes('nofollow'),
		};
	}
	return { directives };
}

export function setSchemaSettings(crawlSettings) {
	let schema = true,
		preventCrawl;
	if (crawlSettings === null) {
		schema = false;
	} else if (crawlSettings?.forhindreIndeksering !== null) {
		preventCrawl = crawlSettings?.forhindreIndeksering?.length
			? crawlSettings?.forhindreIndeksering
			: [];
		schema =
			preventCrawl?.includes('noindex') &&
			preventCrawl?.includes('nofollow')
				? false
				: true;
	}
	return schema;
}
