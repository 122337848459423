import React from 'react';
import styled from 'styled-components';

const StyledElement = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 0;
`;

/**
 * Represents a component for supporting text.
 * @param {Object} props - The properties for the SupportingText component.
 * @param {string} props.element - The element to render.
 * @param {string} props.className - The class name for the component.
 * @param {ReactNode} props.children - The children of the component.
 * @param {string} props.style - The style for the component.
 * @returns {ReactNode} - A React element representing the SupportingText component.
 */
export default function SupportingText({
	element = 'p',
	className = '',
	children,
	style,
}) {
	if (!children) return null;

	return (
		<StyledElement
			as={element}
			className={`supporting-text ${className || ''}`}
			style={style}>
			{children}
		</StyledElement>
	);
}
