import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion';

const ToolTipWrapper = styled.div`
	display: inline-block;
	position: relative;
	z-index: 200;
	${p =>
		p.size === 'large' &&
		css`
			${p =>
				p.theme.media.smallOnly(css`
					position: static;
				`)}
		`}
`;

const Tip = styled(motion.div)`
	position: absolute;
	width: 240px;
	background-color: ${p => p.theme.colors.grey200};
	color: ${p => p.theme.colors.grey900};
	text-align: center;
	padding: 15px;
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: ${p => p.theme.utils.borderRadius};
	font-size: 14px;
	line-height: 22px;

	${p =>
		p.size === 'large' &&
		css`
			width: 590px;
			padding: 30px;
			text-align: left;
			background-color: ${p => p.theme.colors.white};
			border-radius: ${p => p.theme.utils.borderRadiusLarge};
			${p =>
				p.theme.media.medium(css`
					padding: 45px;
				`)}
			${p =>
				p.theme.media.smallOnly(css`
					width: calc(100vw - 60px);
					right: 0vw !important;
					left: 0vw;
					margin: auto;
					z-index: 2;
				`)}
		`}

	${p =>
		p.theme.media.XSmallOnly(css`
			right: calc(100% + 5px);
		`)}

	${p =>
		p.position === 'left' &&
		css`
			top: -5px;
			right: 105%;
		`}

	${p =>
		p.position === 'right' &&
		css`
			top: -5px;
			left: 105%;
		`}

	${p =>
		p.position === 'top' &&
		css`
			bottom: 100%;
			left: 50%;
			margin-left: -120px; /* Use half of the width (240/2 = 120), to center the tooltip */
			${p =>
				p.size === 'large' &&
				css`
					margin-left: -295px; /* Use half of the width (590/2 = 120), to center the tooltip */
				`}
		`}

	${p =>
		p.position === 'bottom' &&
		css`
			top: 100%;
			left: 50%;
			margin-left: -120px; /* Use half of the width (240/2 = 120), to center the tooltip */
			${p =>
				p.size === 'large' &&
				css`
					margin-left: -295px; /* Use half of the width (590/2 = 120), to center the tooltip */
				`}
		`}

	p {
		margin: 0;
		font-weight: 400;
		font-size: 17px;
		line-height: 29px;
		${p =>
			p.size !== 'large' &&
			css`
				font-size: 14px;
				line-height: 22px;
			`}
	}
`;

const ToolTipButton = styled.button`
	background: transparent;
	border: 0;
	padding-left: 0;
	&:hover {
		svg,
		span {
			color: ${p => p.theme.colors.blue800};
		}
	}
	span {
		border-bottom: 1px dotted ${p => p.theme.colors.blue600};
		transition: color 350ms ease;
		color: ${p => p.theme.colors.blue600};
	}
	svg {
		transition: color 350ms ease;
		vertical-align: baseline !important;
		width: 12px;
		transition: color 350ms ease;
		margin-left: 5px;
		color: ${p => p.theme.colors.blue600};
	}
`;

export default function ToolTip({
	children,
	delay = 400,
	position = 'left',
	size = 'small',
	...props
}) {
	let timeout;
	const [activeToolTip, setActiveToolTip] = useState(null);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActiveToolTip(props?.id);
		}, delay || 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActiveToolTip(null);
	};

	return (
		<ToolTipWrapper
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
			position={position}
			size={size}
			{...props}>
			<ToolTipButton
				aria-controls="tip"
				aria-haspopup={true}
				aria-expanded={activeToolTip === props?.id}
				{...props}>
				{props?.title && <span>{props?.title}</span>}
				<FontAwesomeIcon
					icon={faCircleQuestion}
					width="12"
					height="12"
					size="lg"
				/>
			</ToolTipButton>
			<AnimatePresence>
				{activeToolTip === props?.id && (
					<Tip
						id="tip"
						className="tip-modal"
						position={position}
						size={size}
						initial="inactive"
						animate="active"
						exit="inactive"
						variants={{
							active: { opacity: 1, y: 0 },
							inactive: { opacity: 0, y: 10 },
						}}
						transition={{
							duration: 0.15,
						}}>
						{children}
					</Tip>
				)}
			</AnimatePresence>
		</ToolTipWrapper>
	);
}
