import { useState, useEffect } from 'react';
import { gql, useApolloClient } from '@apollo/client';

export const useCrisisMessages = () => {
	const [crisisMessagesLoading, setCrisisMessagesLoading] = useState(false);
	const [crisisMessages, setCrisisMessages] = useState([]);
	const [activeMessages, setActiveMessages] = useState([]);
	const [notSeenMessages, setNotSeenMessages] = useState([]);
	const [closedMessages, setClosedMessages] = useState([]);

	const client = useApolloClient();

	// Fetch crisis messages
	useEffect(() => {
		let messagesSeen = localStorage.getItem('nte-crisis-messages-seen');
		messagesSeen = (messagesSeen && JSON.parse(messagesSeen)) || [];

		if (messagesSeen.length > 0) setClosedMessages(messagesSeen);

		setCrisisMessagesLoading(true);
		client
			.query({ query: GET_ALL_CRISIS_MESSAGES })
			.then(res => {
				const messages = res?.data?.crisisMessages;

				if (!messages?.length) return;

				setCrisisMessages(messages);
			})
			.catch(err => {
				console.log(err);
				setCrisisMessages([]);
			})
			.finally(() => {
				setCrisisMessagesLoading(false);
			});

		//eslint-disable-next-line
	}, []);

	// Update messages
	useEffect(() => {
		if (crisisMessagesLoading || crisisMessages?.length === 0) return;

		// Update the messages from the query
		updateMessages();

		//eslint-disable-next-line
	}, [crisisMessages?.length > 0, crisisMessagesLoading]);

	function closeCrisisMessage(id) {
		const messageToClose = document.getElementById(id);
		const wrapper = document.getElementById('siteEmergencyMessage');

		if (!closedMessages.includes(id))
			setClosedMessages(closedMessages => [...closedMessages, id]);

		if (messageToClose) messageToClose.style.display = 'none';

		setTimeout(function () {
			if (messageToClose) messageToClose.style.display = 'none';
			let closedAll = true;
			if (wrapper)
				wrapper.childNodes.forEach(function (childNode) {
					if (childNode.style.display !== 'none') {
						closedAll = false;
					}
				});
			if (closedAll && wrapper) {
				wrapper.style.display = 'none';
			}
		}, 500);

		// Update local storage
		let currentLocalStorage = localStorage.getItem(
			'nte-crisis-messages-seen'
		);
		currentLocalStorage = currentLocalStorage
			? JSON.parse(currentLocalStorage)
			: [];

		currentLocalStorage.push(id);

		currentLocalStorage = [...new Set(currentLocalStorage)];

		localStorage.setItem(
			'nte-crisis-messages-seen',
			JSON.stringify(currentLocalStorage)
		);

		updateMessages();
	}

	function updateMessages() {
		const today = new Date();
		const active = crisisMessages.filter(message => {
			if (new Date(message?.expirationDate) < today) return false;
			return message?.showCrisisMessage;
		});

		let seenMessageIds = localStorage.getItem('nte-crisis-messages-seen');
		seenMessageIds = seenMessageIds ? JSON.parse(seenMessageIds) : [];

		const notSeen = active.filter(
			message => !seenMessageIds.includes(message._id)
		);

		setActiveMessages(active);
		setNotSeenMessages(notSeen);
	}

	return {
		crisisMessagesLoading,
		setCrisisMessagesLoading,
		crisisMessages,
		activeMessages,
		setActiveMessages,
		notSeenMessages,
		setNotSeenMessages,
		closedMessages,
		setClosedMessages,
		closeCrisisMessage,
		updateMessages,
	};
};

/**
 * Get all crisis messages
 */
const GET_ALL_CRISIS_MESSAGES = gql`
	query findAllCrisisMessages {
		crisisMessages: findAllCrisisMessages {
			_id
			text
			showCrisisMessage
			category
			expirationDate
			sticky
			links {
				link
				title
				target
			}
		}
	}
`;
