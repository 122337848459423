import { UTM_SESSION_KEY } from './analytics';

export const sources = {
	default: 'WEB\\hoved',
	app: 'App\\Hoved',
	strompris: 'WEB\\Strompris',
	krebreal: 'Borettslag\\BoMidt',
	braze: 'Web\\Braze',
};

export const NTE_UTM_SOURCE = 'nte-utm-source';

function getSalesChannelFromSource() {
	if (typeof window === 'undefined') {
		return;
	}
	const source = sessionStorage?.getItem(NTE_UTM_SOURCE);
	return mapParamToSource(source);
}

function getSalesChannelFromUtmParams() {
	if (typeof window === 'undefined') {
		return;
	}
	const utmParams = sessionStorage.getItem(UTM_SESSION_KEY);
	if (utmParams && utmParams !== 'undefined') {
		const source = JSON.parse(utmParams)?.source;
		return mapParamToSource(source);
	}
}

function mapParamToSource(param) {
	// TODO: use API for this?
	switch (param) {
		case 'mitt_nte':
			return sources.app;
		case 'nte_kundeapp':
			return sources.app;
		case 'strompris':
			return sources.strompris;
		case 'strompris.no':
			return sources.strompris;
		case 'krebreal':
			return sources.krebreal;
		case 'krebreal.no':
			return sources.krebreal;
		case 'braze':
			return sources.braze;
		case 'power':
			return sources.power;
		default:
			return;
	}
}

export default function getKilde() {
	return (
		getSalesChannelFromSource() ||
		getSalesChannelFromUtmParams() ||
		sources.default
	);
}
