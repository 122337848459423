import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons/faFilePdf';
import { faFileWord } from '@fortawesome/pro-light-svg-icons/faFileWord';
import { faFileVideo } from '@fortawesome/pro-light-svg-icons/faFileVideo';
import { faFilePowerpoint } from '@fortawesome/pro-light-svg-icons/faFilePowerpoint';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFileZipper } from '@fortawesome/pro-light-svg-icons/faFileZipper';

import { AnchorLink } from 'components/Link';

const StyledLink = styled(AnchorLink)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: ${p => (p.centered ? 'center' : 'flex-start')};
	color: ${p => p.theme.colors.blue600};
	text-decoration: none;
	text-align: left;
	${p =>
		p.theme.media.smallOnly(css`
			align-items: flex-start;
		`)}
	&:not(:last-of-type) {
		margin-bottom: 15px;
	}
	span {
		line-height: 20px;
		text-decoration: underline;
		max-width: calc(100% - 32px);
		${p =>
			p.theme.media.smallOnly(css`
				width: calc(100% - 32px);
				line-height: 26px;
			`)}
	}
	&:hover {
		color: ${p => p.theme.colors.blue600};
		span {
			border-bottom-color: ${p => p.theme.colors.blue600};
		}
		svg {
			transform: unset !important;
		}
	}
	svg {
		margin: 0 10px 0 0;
		* {
			fill: ${p => p.theme.colors.blue600};
		}
	}
`;

const FileEnding = styled.em`
	font-style: normal;
	margin-left: 5px;
`;

export default function FileLink({ href, text, ...props }) {
	if (!href && !text) return null;

	let icon = faFile;
	switch (props?.type) {
		case 'pdf':
			icon = faFilePdf;
			break;

		case 'word':
			icon = faFileWord;
			break;

		case 'video':
			icon = faFileVideo;
			break;

		case 'powerpoint':
			icon = faFilePowerpoint;
			break;

		case 'excel':
			icon = faFileExcel;
			break;

		case 'zip':
			icon = faFileZipper;
			break;

		default:
			icon = faFile;
			break;
	}

	return (
		<StyledLink
			href={href}
			aria-label={props?.ariaLabel}
			target={props?.target}>
			{icon && (
				<FontAwesomeIcon
					icon={icon}
					size="xl"
					style={{
						width: props?.type !== 'file' ? '20px' : '16px',
						height: props?.type !== 'file' ? '25px' : '23px',
					}}
					title={
						props?.type !== 'file'
							? `Ikon av ${props?.type}-fil`
							: 'Ikon av fil'
					}
				/>
			)}
			<span>
				{text}
				{props?.showtype === 'true' && (
					<FileEnding>(.{href.split('.').pop()})</FileEnding>
				)}
			</span>
		</StyledLink>
	);
}
