import { getCookie } from 'context/AnalyticsProvider';
import getOS from 'libs/platform';
export const UTM_SESSION_KEY = 'nte-utm-params';
export const REFERRER_SESSION_KEY = 'nte-referrer';

export const integrationCategories = {
	cookie_cat_necessary: ['Segment.io'],
	cookie_cat_functional: [
		'Algolia',
		'Ninetailed',
		'Intercom Web (Actions)',
		'Intercom Cloud Mode (Actions)',
		'Google Tag Manager',
	],
	cookie_cat_statistic: ['Mixpanel (Actions)'],
	cookie_cat_marketing: [
		'Facebook Conversions API (Actions)',
		'Facebook Pixel',
		'Actions Google Analytic 4',
		'Google AdWords New',
		'Google Enhanced Conversions',
		'Snap Conversions Api',
		'Tiktok Conversions',
	],
};

export const allIntegrations = Object.keys(integrationCategories).reduce(
	(total, categoryName) => {
		const category = integrationCategories[categoryName];
		if (!category) return total;

		category.forEach(integration => (total[integration] = true));

		return total;
	},
	{}
);

const translateCategory = {
	cookie_cat_necessary: 'tekniske',
	cookie_cat_functional: 'funksjonelle',
	cookie_cat_statistic: 'statistikk',
	cookie_cat_marketing: 'markedsføring',
};

export function getStoredConsentCategories() {
	return Object.keys(window.currentConsent)
		.filter(key => window.currentConsent[key] && translateCategory[key])
		.map(key => translateCategory[key]);
}

export function getConsent() {
	let consent = { All: false };
	try {
		if (typeof window === 'undefined') {
			throw new Error('Window or CookieInformation not defined');
		}

		if (typeof window?.cicc?.cookie_cat_statistic === 'undefined') {
			window.cicc = {
				...window.cicc,
				cookie_cat_statistic: true,
			};
			window?.CookieInformation?.sendConsent(JSON.stringify(window.cicc));
		}

		Object.keys(integrationCategories)?.reduce((acc, current) => {
			const category = integrationCategories[current];
			category.forEach(
				integration => (acc[integration] = !!window?.cicc[current])
			);
			return acc;
		}, consent);
	} catch (error) {
		console.log('getConsent', error.message);

		Object.keys(integrationCategories)?.reduce((acc, current) => {
			const category = integrationCategories[current];
			category.forEach(
				integration =>
					(acc[integration] =
						current === 'cookie_cat_necessary' ||
						current === 'cookie_cat_statistic')
			);
			return acc;
		}, consent);
	} finally {
		return consent;
	}
}

export function setupContext(ctx = {}) {
	const context = { ...ctx };

	try {
		const os = getOS();
		context.os = os;

		context.ip = sessionStorage.getItem('nte-ip') || undefined;

		const urlParams = new URLSearchParams(window.location.search);
		const paramsObj = Object.fromEntries(urlParams.entries());

		if (!context.campaign || Object.keys(context.campaign).length === 0) {
			const storedUrlParams = sessionStorage.getItem(UTM_SESSION_KEY);

			context.campaign = storedUrlParams
				? JSON.parse(storedUrlParams)
				: {
						source: 'direct',
						medium: 'none',
						name: 'not set',
						content: 'not set',
				  };
		}

		Object.keys(paramsObj).forEach(key => {
			if (key.toLocaleLowerCase().startsWith('utm_')) {
				context.campaign[key.toLocaleLowerCase().replace('utm_', '')] =
					paramsObj[key];
			}
		});

		context.campaign = {
			...context?.campaign,
			name:
				context?.campaign?.name ||
				context?.campaign?.campaign ||
				'not set',
			source: context?.campaign?.source || 'direct',
			medium: context?.campaign?.medium || 'none',
			content: context?.campaign?.content || 'not set',
		};

		sessionStorage.setItem(
			UTM_SESSION_KEY,
			JSON.stringify(context.campaign)
		);

		const pixel = {};
		const clickId = {};

		// facebook click id and pixel
		if (paramsObj.fbclid) {
			clickId.facebook = `fb.1.${new Date().getTime()}.${
				paramsObj.fbclid
			}`;
		} else {
			clickId.facebook = getCookie('_fbc');
		}
		pixel.facebook = getCookie('_fbp');

		// google click id and pixel
		if (paramsObj.gclid) {
			clickId.google = `GCL.${Math.floor(new Date().getTime() / 1000)}.${
				paramsObj.gclid
			}`;
		} else {
			clickId.google = getCookie('_gcl_aw');
		}
		pixel.google = getCookie('_ga')?.substring(6);

		// tiktok click id and pixel
		if (paramsObj.ttclid) {
			clickId.tiktok = paramsObj.ttclid;
		} else {
			clickId.tiktok = getCookie('ttclid');
		}
		pixel.tiktok = getCookie('_ttp');

		// snapchat click id and pixel
		if (paramsObj.ScCid) {
			clickId.snapchat = paramsObj.ScCid;
		} else {
			clickId.snapchat = getCookie('_scsrid').replace('2%7C', '');
		}
		pixel.snapchat = getCookie('_scid');

		context.clickId = clickId;
		context.pixel = pixel;

		// Referrer
		context.page = {
			referrer: sessionStorage.getItem(REFERRER_SESSION_KEY),
			ref: paramsObj?.ref || '',
		};
		if (
			!context.page.referrer ||
			context.page.referrer === window.location.hostname
		) {
			context.page.referrer = document.referrer;
		}

		if (context.page.referrer) {
			if (isValidUrl(context.page.referrer)) {
				const url = new URL(context.page.referrer);
				context.page.referrer = url.hostname;
			}

			sessionStorage.setItem(REFERRER_SESSION_KEY, context.page.referrer);
		}

		if (typeof window?.cicc?.cookie_cat_statistic === 'undefined') {
			window.cicc = {
				...window.cicc,
				cookie_cat_statistic: true,
			};

			window?.CookieInformation?.sendConsent(JSON.stringify(window.cicc));
		}

		const categoryPreferences = window?.cicc
			? Object.keys(window.cicc).reduce((acc, category) => {
					if (!translateCategory[category]) return acc;
					acc[translateCategory[category]] = window.cicc[category];
					return acc;
			  }, {})
			: {
					tekniske: true,
					statistikk: true,
			  };

		context.categoryPreferences = categoryPreferences;

		context.url = window.location.href;
	} catch (error) {
		console.log('setupContext', error);
	} finally {
		return context;
	}
}

/**
 * Check if a string is a valid URL
 * @param {string} string
 * @returns {boolean}
 */
const isValidUrl = string => {
	try {
		new URL(string);
		return true;
	} catch (_) {
		return false;
	}
};
