export default async function searchAddress(search, callback, onlyVegAdresse) {
	// Documentation: https://ws.geonorge.no/adresser/v1/#/default/get_sok
	try {
		let queryObject = {
			sok: search + '*',
		};

		if (typeof search !== 'string') {
			queryObject = {
				poststed: search?.region,
				postnummer: search?.postal_code,
			};

			const address = search?.street_address?.match(/\r?\n/g)
				? search?.street_address?.split(/\r?\n/g)[0]?.trim()
				: search?.street_address;
			const parts = address.split(',');
			const number =
				parts?.length > 0
					? parts[0]?.match(/\d/g).join('')
					: address.match(/\d/g).join('');
			if (number) {
				queryObject.nummer = number;
			}

			const streetParts = parts?.length
				? parts[0]?.split(number)
				: number
				? address.split(number)
				: address;
			queryObject.adressenavn =
				streetParts?.length > 0
					? streetParts[0].trim()
					: address.trim();

			const letter =
				streetParts?.length > 0 ? streetParts?.pop().trim() : '';
			if (letter) {
				queryObject.bokstav = letter;
			}
		}

		let queryString;
		if (onlyVegAdresse) {
			queryString = new URLSearchParams({
				...queryObject,
				utkoordsys: 4258,
				asciiKompatibel: false,
				treffPerSide: 5,
				objtype: 'Vegadresse',
				side: 0,
			}).toString();
		} else {
			queryString = new URLSearchParams({
				...queryObject,
				utkoordsys: 4258,
				asciiKompatibel: false,
				treffPerSide: 5,
				side: 0,
			}).toString();
		}

		const addresses = await fetch(
			`https://ws.geonorge.no/adresser/v1/sok?${queryString}`
		)
			.then(response => response.json())
			.then(json => {
				if (!json?.adresser?.length > 0) {
					throw new Error('No address found');
				}

				const addressArray = json?.adresser?.map(address => {
					return {
						resultat: `${address?.adressetekst}, ${address?.postnummer} ${address?.poststed}`,
						gate: address?.adressenavn,
						husnummer:
							address?.nummer &&
							address?.nummer?.toString() + address?.bokstav,
						postnummer: address?.postnummer,
						poststed: address?.poststed,
						pos: {
							lat: address?.representasjonspunkt?.lat,
							lon: address?.representasjonspunkt?.lon,
						},
					};
				});

				const sortedAddressArray = addressArray.sort((a, b) => {
					if (a.resultat < b.resultat) {
						return -1;
					}
					if (a.resultat > b.resultat) {
						return 1;
					}
					return 0;
				});

				return sortedAddressArray;
			})
			.catch(err => {
				throw err;
			});

		if (!addresses?.length > 0) {
			throw new Error('No addresses found');
		}

		callback(addresses);
	} catch (error) {
		console.log(error);

		callback([]);
	}
}
