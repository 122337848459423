import React from 'react';

export default function useHasMounted() {
	const [hasMounted, setHasMounted] = React.useState(false);
	React.useEffect(() => {
		setHasMounted(true);
	}, []);

	return hasMounted;
}
