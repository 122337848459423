import React from 'react';
import styled, { css } from 'styled-components';

import SmallWidth from 'layouts/small-width';
import Spacing from 'layouts/Spacing';

const Wrapper = styled.div`
	text-align: center;
	min-height: ${p => (p.$fullheight === 'true' ? '100vh' : '0')};
	${p =>
		p.theme.media.mediumDown(css`
			min-height: auto;
		`)}
`;

const DotsWrapper = styled.svg`
	width: 100%;
	display: block;
	max-width: 75px;
	margin: 0 auto;
	circle {
		fill: ${p => p.theme.colors.blue600};
	}
`;

/**
 * Loading component
 * @param {Object} props
 * @param {Boolean} props.fullHeight - Whether the loading should be full height or not
 * @param {String} props.text - The text to display below the loading dots (optional)
 * @returns {JSX.Element}
 */
export default function Loading({ fullHeight = 'false', ...props }) {
	return (
		<Wrapper $fullheight={fullHeight} {...props}>
			<Spacing
				spacing={{ top: 'large', bottom: 'xlarge' }}
				className="loading">
				<SmallWidth>
					{/** Animated loading dots */}
					<DotsWrapper
						x="0px"
						y="0px"
						viewBox="0 0 52 20"
						enableBackground="new 0 0 0 0">
						<circle stroke="none" cx="6" cy="10" r="6">
							<animate
								attributeName="opacity"
								dur="2s"
								values="0;1;0"
								repeatCount="indefinite"
								begin="0.1"
							/>
						</circle>

						<circle stroke="none" cx="26" cy="10" r="6">
							<animate
								attributeName="opacity"
								dur="2s"
								values="0;1;0"
								repeatCount="indefinite"
								begin="0.2"
							/>
						</circle>

						<circle stroke="none" cx="46" cy="10" r="6">
							<animate
								attributeName="opacity"
								dur="2s"
								values="0;1;0"
								repeatCount="indefinite"
								begin="0.3"
							/>
						</circle>
					</DotsWrapper>
					{props?.text && (
						<p style={{ marginTop: '20px', fontStyle: 'italic' }}>
							{props.text}
						</p>
					)}
				</SmallWidth>
			</Spacing>
		</Wrapper>
	);
}
