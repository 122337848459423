import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import MaxWidth from 'layouts/max-width';
import Heading from 'libs/heading';
import Link from 'components/Link';
import LoadingDots from 'images/icons/loading-dots-animation.inline.svg';
import Logo from 'components/header/Logo';

const LayoutWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	min-height: 100vh;
	padding: 40px 20px;
`;

const Main = styled.div`
	> div {
		width: 100%;
		text-align: center;
	}
`;

const Wrapper = styled.div`
	text-align: center;
	img {
		margin: 0 auto 40px;
		transform: translateX(-15px);
		${p =>
			p.theme.media.smallOnly(css`
				transform: translateX(-10px);
				height: 60px;
			`)}
	}
	${p =>
		p.theme.media.smallOnly(css`
			padding-top: 0px;
		`)}
	svg {
		width: 70px;
		height: 35px;
		margin: 0 auto 40px;
		display: block;
		fill: ${p => p.theme.colors.blue600};
		${p =>
			p.theme.media.smallOnly(css`
				margin: 0 auto 20px;
			`)}
	}

	p {
		display: block;
		margin: 20px 0 30px;
		${p =>
			p.theme.media.medium(css`
				font-size: 20px;
				line-height: 28px;
				margin: 30px 0 40px;
			`)}
	}
`;

export default function Redirect({
	title = 'Du blir videresendt',
	text = 'Vennligst vent.',
	error = false,
	to = '',
}) {
	useEffect(() => {
		// If doesn´t have "to"-variable then don´t continue
		if (!to) return;

		console.log('Redirecting to:', to);

		window.location.href = to;
	}, [to]);

	return (
		<LayoutWrapper id="wrapper" data-cy="page" aria-live="polite">
			<Main id="main" data-cy="main">
				<MaxWidth>
					<Wrapper error={error}>
						<Logo />
						{!error ? (
							<>
								<Heading level="h1">{title}</Heading>
								<p>{text}</p>
								<LoadingDots width="70" height="35" />
								<Link to="/" title="Gå til forsiden">
									Gå til forsiden
								</Link>
							</>
						) : (
							<>
								<Heading level="h1">
									Ops! Her skjedde det en feil
								</Heading>

								<p>
									<Link to="/" title="Gå til forsiden">
										Gå til forsiden
									</Link>
									{' eller '}
									<Link
										to="/kundeservice/chat/"
										title="Ta kontakt med oss">
										ta kontakt med oss
									</Link>{' '}
									så finner vi ut av det sammen.
								</p>
							</>
						)}
					</Wrapper>
				</MaxWidth>
			</Main>
		</LayoutWrapper>
	);
}
