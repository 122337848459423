import { useState, useEffect } from 'react';

const useSnowfall = (isLoading, cookieConsentHasRun) => {
	const [showSnowfall, setShowSnowfall] = useState(null);

	useEffect(() => {
		if (isLoading || (!!window?.CookieInformation && !cookieConsentHasRun))
			return;

		const hasShownSnowfall = sessionStorage.getItem('nte-hasShownSnowfall');

		if (hasShownSnowfall === 'true') {
			setShowSnowfall(false);
			return;
		}

		if (hasShownSnowfall !== 'true') {
			setShowSnowfall(true);
			sessionStorage.setItem('nte-hasShownSnowfall', '');

			// Start fading out after 10 seconds
			const timer = setTimeout(() => {
				setShowSnowfall(false);
				sessionStorage.setItem('nte-hasShownSnowfall', 'true');
			}, 10000);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [isLoading, cookieConsentHasRun]);

	return showSnowfall;
};

export default useSnowfall;
