import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import Spacing, { isNoSpacing } from 'layouts/Spacing';

const WidthLimit = styled.div`
	max-width: ${p =>
		(p.$width && p.theme.widths[p.$width]) || p.theme.widths.default};
	margin: 0 auto;
	padding: ${p =>
		(!p.$noSpacing && `0 ${p.theme.gutters.xsmall}`) ||
		p.theme.gutters.xsmall};
`;

export const ComponentWrapperStyle = p => {
	const transparentBg = !p?.bg || p?.bg?.toLowerCase() === 'transparent';
	return css`
		${p =>
			!!p.bg &&
			css`
				background-color: ${p => getBackgroundColor(p, transparentBg)};
				color: ${p => getTextColor(p)};

				h3 {
					margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
				}

				h2,
				h3,
				h4,
				h5,
				h6,
				p,
				a:not([type='button']):not(role='button'),
				ul,
				div,
				ol {
					color: ${p => getTextColor(p)};
				}

				ol li:before {
					background-color: ${p => getOlColor(p)} !important;
				}

				ul li {
					svg {
						color: ${p => getUlColor(p)} !important;
					}
				}
			`};

		// When has background-image
		${p =>
			p.bgimage &&
			css`
				background-image: url(${p.bgimage});
				background-size: cover;
				background-position: center;
				align-items: center !important;
				${p =>
					p.hidemediaonmobile === 'true' &&
					p.theme.media.smallOnly(css`
						background-image: none;
					`)}
			`}

		// When has background-color and should not be wider than the max-width
		${p =>
			!!p.bg &&
			p.maxWidth &&
			css`
				max-width: ${p => `calc(${p.theme.widths.default} + 120px)`};
				margin: auto;
				padding: 68px 60px !important;
				${p =>
					p.theme.media.large(css`
						border-radius: ${p => p.theme.utils.borderRadius};
					`)}
				${p =>
					p.theme.media.mediumDown(css`
						padding: 30px 0 !important;
					`)}
			`}

		// When is fullwidth, has background image 
		${p =>
			p.bgimage &&
			p.layout === 'fullwidth' &&
			!!p.bg &&
			css`
				position: relative;
				.component__article {
					// Make sure the text is on top of the gradient
					position: relative;
					z-index: 3;
				}
			`}
	`;
};

export const ComponentWrapperPadding = p => {
	return css`
		${p =>
			!!p.bg &&
			css`
				padding: ${p =>
					`calc(${p.theme.spacing.desktop.large} + ${p.theme.spacing.desktop.small}) 0`};

				${p =>
					p.theme.media.mediumOnly(css`
						padding: ${p =>
							`calc(${p.theme.spacing.tablet.large} + ${p.theme.spacing.tablet.small}) 0`};
					`)}
				${p =>
					p.theme.media.smallOnly(css`
						padding: ${p =>
							`calc(${p.theme.spacing.mobile.large} + ${p.theme.spacing.mobile.small}) 0`};
					`)}
				
					// If the background is transparent and index is 1, remove padding-top
				${p =>
					p?.bg?.toLowerCase() === 'transparent' &&
					css`
						padding: 0;
						${p =>
							p.theme.media.mediumOnly(css`
								padding: 0;
							`)}
						${p =>
							p.theme.media.smallOnly(css`
								padding: 0;
							`)}
					`}
			`};
	`;
};

const Inner = styled.div`
	${ComponentWrapperStyle}
	${ComponentWrapperPadding}
`;

/**
 * A wrapper component that provides width and spacing options for its children.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.width='default'] - The width of the component. Defaults to 'default'.
 * @param {ReactNode} props.children - The child components to be wrapped.
 * @param {...Object} props - The rest of the properties passed to the component.
 *
 * @returns {ReactNode} The wrapped children with the specified width and spacing.
 */
export function ComponentWrapper({ width = 'default', children, ...props }) {
	// If the spacing is set to 'none' for both top and bottom, the spacing is removed.
	let noSpacing = isNoSpacing(props?.spacing);

	// The class name for the component.
	const className = props?.className || 'component-wrapper';

	// The content of the component.
	const content = (
		<Inner data-cy={className} {...props}>
			<WidthLimit
				$width={width}
				$noSpacing={noSpacing}
				className={`${className}__width-limit ${
					width === 'default' ? 'max-width' : `${width}-width`
				}`}>
				{children}
			</WidthLimit>
		</Inner>
	);

	// Return the content with or without spacing wrapper
	return noSpacing ? (
		content
	) : (
		<Spacing data-cy={className} {...props}>
			{content}
		</Spacing>
	);
}

/**
 * Get the background color for the component.
 * @param {Object} p - The properties passed to the component.
 * @param {boolean} transparentBg - Whether the background is transparent.
 * @returns {string} The background color for the component.
 */
function getBackgroundColor(p, transparentBg) {
	if (!!p.theme.colors[p.bg]) return p.theme.colors[p.bg];
	if (transparentBg) return 'transparent';
	return p.theme.colors.grey200;
}

/**
 * Get the text color for the component.
 * @param {Object} p - The properties passed to the component.
 * @returns {string} The color for the component.
 */
function getTextColor(p) {
	if (p.shadow === 'true') return p.theme.colors.black;
	return p.bg && ['grey900', 'blue800'].includes(p.bg)
		? p.theme.colors.white
		: p.theme.colors.black;
}

/**
 * Get the color for ordered lists.
 * @param {Object} p - The properties passed to the component.
 * @returns {string} The color for ordered lists.
 */
function getOlColor(p) {
	if (['grey200', 'blue800'].includes(p.bg)) {
		return p.theme.colors.blue300;
	}
	if (['blue100', 'green100'].includes(p.bg)) return p.theme.colors.green300;
	if (p.bg === 'grey900') return p.theme.colors.grey300;
	if (p.bg === 'coral100') return p.theme.colors.coral300;
	return p.theme.colors.blue300;
}

/**
 * Get the color for unordered lists.
 * @param {Object} p - The properties passed to the component.
 * @returns {string} The color for unordered lists.
 */
function getUlColor(p) {
	if (['blue100', 'grey200'].includes(p.bg)) {
		return p.theme.colors.blue600;
	}
	if (p.bg === 'green100') return p.theme.colors.green500;
	if (p.bg === 'blue800' && p.shadow !== 'true') return p.theme.colors.white;
	return p.theme.colors.black;
}

const Gradient = styled.div`
	width: 100%;
	height: 100%;
	z-index: 2;
	position: absolute;
	right: 0;
	top: 0;
	background: ${p =>
		`linear-gradient(0deg, ${rgba(p.theme.colors[p.bg], 0.74)}, 
  		${rgba(p.theme.colors[p.bg], 0.74)})`};

	// Gradient overlay on side / center
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}

	/* Left */
	${p =>
		p.align === 'left' &&
		css`
			&::after {
				background: ${p =>
					`linear-gradient(90deg, 
						${rgba(p.theme.colors[p.bg], 1)}, 
						${rgba(p.theme.colors[p.bg], 0)})
					`};
			}
		`}

	/* Center */
	${p =>
		p.align === 'center' &&
		css`
			&::after {
				background: ${p =>
					`linear-gradient(90deg, 
						${rgba(p.theme.colors[p.bg], 0)}, 
						${rgba(p.theme.colors[p.bg], 0.2)},
						${rgba(p.theme.colors[p.bg], 0.5)},
						${rgba(p.theme.colors[p.bg], 1)})
					`};
			}
		`}
`;
/**
 * A gradient component that provides a gradient overlay on the side or center of the component.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.bg - The background color of the component.
 * @param {string} props.align - The alignment of the gradient overlay.
 * @returns {ReactNode} The gradient overlay component.
 */
export function ImageGradient({ ...props }) {
	const transparentBg =
		!props?.bg || props?.bg?.toLowerCase() === 'transparent';

	return (
		<Gradient
			className="gradient"
			{...props}
			bg={transparentBg ? 'grey900' : props?.bg}
		/>
	);
}
