import React from 'react';
import { ThemeProvider as ThemeWrapper } from 'styled-components';

import GlobalStyle from '../GlobalStyle';
import Theme from '../theme';

export default function ThemeProvider({ children }) {
	return (
		<ThemeWrapper theme={Theme}>
			<GlobalStyle />
			{children}
		</ThemeWrapper>
	);
}
