import { useRef } from 'react';

export default function useTimer() {
	const ref = useRef(null);

	function startTimer() {
		ref.current = new Date().getTime();
	}

	function stopTimer() {
		if (!ref?.current) return 0;
		const now = new Date().getTime();
		return Math.round((now - ref.current) / 1000);
	}

	return {
		startTimer,
		stopTimer,
	};
}
