import React from 'react';
import styled, { css } from 'styled-components';

import useWindow from 'context/useWindow';
import { fadeIn } from 'libs/animations';
import {
	getCorrectContentType,
	getUrl,
	getUrlByType,
	outputPlainText,
	shortenString,
} from 'libs/content';
import { AnimationWrap } from 'libs/animations';
import { ConditionalLink } from 'components/Link';
import Media from 'parts/image/Media';
import TitleAndText from 'parts/title-and-text/TitleAndText';

const Wrap = styled.div`
	// Media content
	.component__article--media {
		> div {
			position: relative;
		}
		${p =>
			p.theme.media.smallOnly(css`
				padding-top: ${p => (p.align === 'right' ? '30px' : '0')};
				padding-bottom: ${p => (p.align === 'right' ? '0' : '30px')};
			`)}

		// Inner link
		.link {
			position: relative;
			img,
			video {
				border-radius: ${p => p.theme.utils.borderRadius};
			}
		}
		// Video
		.component__video.max-width {
			padding: 0;
		}
		// Svg image
		.svg-image {
			text-align: center;
			svg {
				max-width: 100%;
				animation-name: ${fadeIn};
				animation-duration: 500ms;
				animation-fill-mode: forwards;
				opacity: 0;
				${p =>
					p.$imageheight &&
					css`
						height: ${p.$imageheight}px !important;
					`}
			}
		}
	}
	// Text content
	.component__article--text {
		.component__title-and-text .text {
			margin-bottom: 0;
		}
	}

	// When placement of components is horizontal (image next to text for large screens)
	${p =>
		p.direction === 'horizontal' &&
		css`
			display: flex;
			flex-direction: ${p =>
				p.align === 'right' ? 'row-reverse' : 'row'};
			align-items: center;
			${p =>
				p.theme.media.smallOnly(css`
					flex-direction: column;
					> div {
						width: 100%;
						text-align: center;
					}
				`)}

			${p =>
				p.theme.media.medium(css`
					.component__article--media {
						padding: 0 20px 0 0;
						flex: 0 0 50%;
						text-align: inherit;
					}
					.component__article--text {
						flex: 0 0 50%;
						padding: 0 0 0 20px;
					}
				`)}
		`}

	// When placement of components is vertical (image above text)
	${p =>
		p.direction === 'vertical' &&
		css`
			.component__article--media {
				padding-bottom: ${p => (p.align === 'right' ? '0' : '20px')};
				${p =>
					p.theme.media.medium(css`
						padding-bottom: ${p => p.theme.spacing.desktop.small};
					`)}
			}
			.component__article--text {
				.medium-width {
					padding: 0;
				}
			}
		`}
`;

/**
 * An article component, used to display an article with media and text
 * @param {object} props - The props of the component
 * @param {string} props.as - The tag of the component
 * @param {string} props.direction - The placements of the components in the article (horizontal/vertical)
 * @param {boolean} props.showTags - Flag to indicate if tags should be shown
 * @param {string} props.imageclick - Flag to indicate if the image should be clickable
 * @param {string} props.hidemediaonmobile - Flag to indicate if the media should be hidden on mobile
 * @returns {React.ReactNode} The article component
 */
export default function Article({
	as = 'article',
	direction = 'vertical',
	hidemediaonmobile = 'false',
	showTags = false,
	imageclick = 'true',
	...props
}) {
	// Parse the data to be used in the component
	props = { ...props, ...parseData(props) };

	return (
		<Wrap
			as={as}
			className={`${props?.className || ''} component__article`}
			$imageheight={props?.imageheight}
			direction={direction}>
			<MediaContent {...props} hidemediaonmobile={hidemediaonmobile}>
				<ConditionalLink
					clickable={
						imageclick === 'true' && !props?.video?.file?.url
					}
					to={props?.to || props?.href}>
					<Media direction={direction} {...props} />
				</ConditionalLink>
			</MediaContent>
			<TextContent showTags={showTags} {...props}>
				{props?.component || props?.children}
			</TextContent>
		</Wrap>
	);
}

/**
 * The media content of the article (image, video, etc.)
 * @param {object} props - The props of the component
 * @param {React.ReactNode} children - The children of the component
 * @returns {React.ReactNode} The media content of the article
 */
function MediaContent({ children, ...props }) {
	const { windowWidth } = useWindow();
	const isMobile = windowWidth < 768;

	if (
		(isMobile && props?.hidemediaonmobile === 'true') ||
		!children ||
		!(props?.image || props?.video)
	) {
		return <></>;
	}

	return (
		<div className="component__article--media">
			<AnimationWrap
				doanimate={props?.transitions}
				direction="left"
				delay={0.5}>
				{children}
			</AnimationWrap>
		</div>
	);
}

/**
 * The text content of the article
 * @param {object} props - The props of the component
 * @param {React.ReactNode} children - The children of the component
 * @returns {React.ReactNode} The text content of the article
 */
function TextContent({ children, ...props }) {
	if (!props?.title && !props?.text && !children) return <></>;

	const settings = props?.settings || [];

	return (
		<div className="component__article--text">
			<AnimationWrap doanimate={props?.transitions} direction="right">
				<TitleAndText
					{...props}
					transitionEach={false}
					nested={true}
					spacing={{ bottom: 'none' }}
					centered={props?.align === 'center' ? 'true' : 'false'}
					settings={
						(props?.linkelement === 'text' && [
							...settings,
							'Vis knapp som ren tekst',
						]) ||
						settings
					}
					{...settings}>
					{children}
				</TitleAndText>
			</AnimationWrap>
		</div>
	);
}
/**
 * Parses the article data to be used in the default component
 * @param {object} article - The article to parse
 * @returns {object} The parsed article
 */
function parseData(article) {
	if (!article) return;

	let title = article?.title || article?.name || article?.tittel;

	const content =
		article?.desc ||
		article?.intro ||
		article?.content ||
		article?.beskrivelse ||
		article?.description;

	const contentType =
		article?.type === 'Bloggartikkel'
			? 'blogg'
			: getCorrectContentType(article?.internal?.type);

	let to = article?.slug ? getUrl(article?.slug, contentType) : undefined;

	let buttons = [];

	// If buttons are set, loop through them and set the to property and add them to the buttons array
	if (article?.buttons?.length > 0) {
		for (let i = 0; i < article?.buttons?.length; i++) {
			const btn = article?.buttons[i];
			to = btn?.to || to; // TODO: check if this is correct
			if (btn?.linkref?.slug && btn?.linkref?.__typename) {
				to = getUrlByType(btn?.linkref?.slug, btn?.linkref?.__typename);
			}

			buttons.push({
				...btn,
				to,
			});
		}
	}

	// If no buttons are set, then use the default "Les mer" button
	if (!article?.buttons?.length > 0 && to) {
		buttons.push({
			text: article?.linkText || article?.buttonText || `Les mer`,
			to,
			outlined: 'true',
		});
	}

	// When article is a blog post, then parse the group
	let group;
	if (
		article?.type === 'Bloggartikkel' ||
		article?.internal?.type === 'ContentfulInnholdInnlegg'
	) {
		if (article?.category?.length > 0) {
			group = article?.category?.map(c => ({
				title: c,
				visibility: true,
			}));
		} else if (article?.group?.length > 0) {
			group = article?.group;
		}
		to = getUrl(article?.slug, 'blogg');
	}

	// If type is "ContentfulInnholdReferanse" then hide title and use it below the content instead
	if (article?.__typename === 'ContentfulInnholdReferanse') {
		title = null;
		const className = 'name';
		const classString = `<p class="${className}">`;

		if (
			content?.childMarkdownRemark?.html &&
			!content?.childMarkdownRemark?.html?.includes(classString)
		) {
			content.childMarkdownRemark.html += `${classString}- ${article?.title}</p>`;
		}
	}

	return {
		...article,
		to,
		group,
		title,
		text:
			(typeof content === 'string' &&
				shortenString(outputPlainText(content), 175)) ||
			content,
		image:
			article?.layout === 'fullwidth'
				? undefined
				: article?.meta?.image ||
				  article?.image ||
				  article?.bilde ||
				  (article?.images?.length > 0 && article?.images[0]),
		buttons,
		createdAt: undefined,
		created: article?.createdAt || article?.date,
	};
}

const GridWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: ${p => p.theme.spacing.desktop.medium};
	${p =>
		p.theme.media.medium(css`
			margin: ${p => `${p.theme.spacing.desktop.medium} -20px 0`};
		`)}
	.component__article {
		margin: 0 0 45px;
		flex: 1 1 100%;
		max-width: 100%;

		${p =>
			p.theme.media.mediumOnly(css`
				margin: 0 20px 40px;
				flex: 1 1 calc(50% - 40px);
				max-width: calc(50% - 40px);
			`)}

		${p =>
			p.theme.media.large(css`
				margin: 0 20px 40px;
				flex: 1 1 calc(33.333% - 40px);
				max-width: calc(33.333% - 40px);
			`)}
	}
`;

/**
 * Represents a grid component that can be used to wrap multiple articles
 * @param {object} props - The props of the component
 * @param {React.ReactNode} children - The children of the component
 * @returns {React.ReactNode} The grid component
 */
export function Grid({ children, ...props }) {
	if (!children) return <></>;
	return (
		<GridWrap className="component__grid" {...props}>
			{children}
		</GridWrap>
	);
}
