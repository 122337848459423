import { useCallback, useEffect, useState } from 'react';
import useHasMounted from './useHasMounted';

export default function useWindow() {
	const hasMounted = useHasMounted();
	const [windowWidth, setWindowWidth] = useState(
		hasMounted && window.innerWidth
	);
	const [windowHeight, setWindowHeight] = useState(
		hasMounted && window.innerHeight
	);
	const [browser, setBrowser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	// Detect browser and save to state
	useEffect(() => {
		const userAgentString = navigator?.userAgent;

		const browserMap = [
			{ name: 'Brave', condition: () => navigator?.brave },
			{ name: 'Edge', condition: () => userAgentString.includes('Edg') },
			{
				name: 'Chromium',
				condition: () => userAgentString.includes('Chromium'),
			},
			{
				name: 'Chrome',
				condition: () => userAgentString.includes('Chrome'),
			},
			{
				name: 'Firefox',
				condition: () => userAgentString.includes('Firefox'),
			},
			{
				name: 'Safari',
				condition: () => userAgentString.includes('Safari'),
			},
			{ name: 'Opera', condition: () => userAgentString.includes('OP') },
			{
				name: 'Internet Explorer',
				condition: () =>
					userAgentString.includes('MSIE') ||
					userAgentString.includes('rv:'),
			},
		];

		const detectedBrowser =
			browserMap.find(browser => browser.condition())?.name || 'Unknown';

		setBrowser(detectedBrowser);
	}, []);

	// On window resize: Get section height and save to state on render and on resize
	const resizeListener = useCallback(() => {
		setWindowWidth(window.innerWidth);
		setWindowHeight(window.innerHeight);
	}, [setWindowWidth, setWindowHeight]);

	// runs asynchronously and after a render is painted to the screen.
	useEffect(() => {
		//Sets window width at the start
		if (!windowWidth) {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
		}
		window.addEventListener('resize', resizeListener);
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, [windowWidth, resizeListener]);

	useEffect(() => {
		const onPageLoad = () => {
			setIsLoading(false);
		};

		// Check if the page has already loaded
		if (document.readyState === 'complete') {
			onPageLoad();
		} else {
			window.addEventListener('load', onPageLoad, false);
			// Remove the event listener when component unmounts
			return () => window.removeEventListener('load', onPageLoad);
		}
	}, []);

	return {
		windowWidth,
		windowHeight,
		browser,
		isLoading,
		app: {
			iOS: 'https://apps.apple.com/no/app/mitt-nte/id1151770729',
			android:
				'https://play.google.com/store/apps/details?id=no.energihjem.mobil',
		},
	};
}
