import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';

import setupHeaders from 'libs/setup-headers';
import { removeQueryParam } from './AnalyticsProvider';

const prefix = 'nte-email-';
export const tokenKey = prefix + 'token-data';
const userKey = prefix + 'user-data';

export default function useEmailAuth() {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const tokenData = localStorage.getItem(tokenKey) || null;
				const userData = await JSON.parse(
					localStorage.getItem(userKey)
				);

				const parsedToken = parseJWT(tokenData);

				const isExpired =
					!parsedToken || parsedToken?.exp < dayjs().unix();

				if (!isExpired && tokenData && userData) {
					const urlParams = new URLSearchParams(
						window.location.search.split('?').pop()
					);
					const email = urlParams.get('email');
					if (email && email !== userData.email) {
						localStorage.removeItem(tokenKey);
						localStorage.removeItem(userKey);
						setToken(null);
						setUser(null);
						setLoading(false);
						window.location.reload();
						return;
					}

					setToken(tokenData);
					setUser(userData);
					setLoading(false);
					return;
				} else {
					localStorage.removeItem(tokenKey);
					localStorage.removeItem(userKey);
					setToken(null);
					setUser(null);
				}

				if (
					!tokenData &&
					window.location.search.includes('secret=') &&
					window.location.search.includes('email=')
				) {
					const urlParams = new URLSearchParams(
						window.location.search.split('?').pop()
					);
					const secret = urlParams.get('secret');
					const email = urlParams.get('email');

					removeQueryParam('secret');
					removeQueryParam('email');

					if (secret && email) {
						await getToken({
							secret,
							email,
						});
					}
				}
			} catch (error) {
				console.log(error);
			}

			setLoading(false);
		})();

		//eslint-disable-next-line
	}, []);

	async function login({ event, email, ...properties }) {
		try {
			const response = await fetch(
				process.env.GATSBY_API_BASE_URL + 'auth/email/login',
				{
					method: 'POST',
					headers: setupHeaders({
						'Content-Type': 'application/json',
					}),
					body: JSON.stringify({
						event,
						email,
						properties,
					}),
				}
			);

			return response.status === 200;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	async function getToken({ secret, email }) {
		setLoading(true);

		try {
			const response = await fetch(
				process.env.GATSBY_API_BASE_URL + 'auth/email/token',
				{
					method: 'POST',
					headers: setupHeaders({
						'Content-Type': 'application/json',
					}),
					body: JSON.stringify({
						secret,
						email,
					}),
				}
			);

			const { user, token: tokenData } = await response.json();
			// const parsedToken = parseJWT(tokenData);

			localStorage.setItem(tokenKey, tokenData);
			localStorage.setItem(userKey, JSON.stringify(user));
			setToken(tokenData);
			setUser(user);
			setLoading(false);
		} catch (error) {
			console.log(error);
			localStorage.removeItem(tokenKey);
			localStorage.removeItem(userKey);
			setToken(null);
			setUser(null);
			setLoading(false);
		}
	}

	return {
		isAuthenticated: !!token,
		token,
		user,
		loading,
		login,
		logout: ({ redirect }) => {
			localStorage.removeItem(tokenKey);
			localStorage.removeItem(userKey);
			setToken(null);
			setUser(null);
			if (redirect) {
				navigate(redirect);
			}
		},
	};
}

export function parseJWT(token) {
	if (!token) return null;
	return JSON.parse(window.atob(token.split('.')[1]));
}
