import React from 'react';
import { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

export const slideDown = keyframes`
    0% {
        top: -100%;
    }
    100% {
        top: 100%;
    }
`;

export const slideUp = keyframes`
    0% {
        top: 100%;
    }
    100% {
        top: -100%;
    }
`;

export const fadeInDown = keyframes`
    0% {
        opacity: 0;
        transform: translate(0, -50px);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
`;

export const fadeOutUp = keyframes`
    0% {
        opacity: 1;
        transform: translate(0, 0px);
    }
    100% {
        opacity: 0;
        transform: translate(0, -50px);
    }
`;

export const fadeOpacity = keyframes`
    from {
        opacity: 0.8;
    }
    to {
        opacity: 0.2;
    }
`;

export const moveDecor = keyframes`
    from {
        transform: translateY(-110px);
        -moz-transform: translateY(-110px);
    }
    to {
        transform: translateY(-80px);
        -moz-transform: translateY(-80px);
    }
`;

export const showContent = keyframes`
    from {
    	transform: translateY(-20px);
    	opacity: 0.5;
    }
    to {
    	transform: translateY(0px);
    	opacity: 1;
    }
`;

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const expandDown = keyframes`
    0% {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
    }
    100% {
        max-height: 500px;
        opacity: 1;
        pointer-events: all;
    }
`;

export const collapseUp = keyframes`
    0% {
        max-height: 500px;
        opacity: 1;
        pointer-events: all;
    }
    100% {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
    }
`;

export const shake = keyframes`
0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
`;

/**
 * Wrap component for animating its entrance and exit using the Framer Motion library.
 *
 * @param {Object} props - The props object for the AnimationWrap component.
 * @param {string} [props.id='motion'] - The id identifier for the motion component.
 * @param {string} [props.doanimate='false'] - Determines whether to apply animation.
 * @param {('left'|'right'|'up'|'down')} [props.direction='left'] - The direction of animation.
 * @param {number} [props.duration=0.5] - The duration of animation in seconds.
 * @param {number} [props.delay=0] - The delay before animation starts in seconds.
 * @param {string} [props.className] - The classname of the component
 * @param {ReactNode} props.children - The content to be animated.
 * @returns {ReactNode} The wrapped component with animation effects.
 */
export function AnimationWrap({
	doanimate = 'false',
	direction = 'left',
	duration = 0.5,
	delay = 0,
	className,
	children,
}) {
	if (!children) return null;
	if (doanimate !== 'true') {
		if (className) return <div className={className}>{children}</div>;
		return children;
	}

	const initial = {},
		exit = {};

	switch (direction) {
		case 'left':
			initial.x = 75;
			exit.x = 75;
			break;

		case 'right':
			initial.x = -75;
			exit.x = -75;
			break;

		case 'up':
			initial.y = 30;
			exit.y = 30;
			break;

		case 'down':
			initial.y = -20;
			exit.y = -20;
			break;

		case 'in':
			initial.y = 0;
			exit.y = 0;
			break;

		default:
			break;
	}

	return (
		<motion.div
			className={className}
			initial={{
				opacity: 0,
				x: initial.x,
				y: initial.y,
			}}
			whileInView={{ opacity: 1, x: 0, y: 0 }}
			viewport={{ once: true }}
			transition={{
				duration,
				delay,
			}}>
			{children}
		</motion.div>
	);
}
