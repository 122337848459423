import { useEffect } from 'react';

/**
 * Observes the intersection of elements within a container and triggers a handler function based on the intersection.
 *
 * @param {RefObject} ref - The reference to the container element being observed.
 * @param {string} selector - The selector for the target elements within the container to be observed.
 * @param {function} handler - The callback function to be executed when an intersection occurs.
 * @param {object} options - Additional options for configuring the intersection observer.
 * @param {string} [options.rootMargin='0px 0px 0px 0px'] - Margin around the root element's boundary.
 * @param {number[]} [options.threshold=[0.75, 1]] - A numeric value or array of numeric values indicating the percentage of the target element's visibility threshold needed to trigger the handler.
 * @param {HTMLElement|null} [options.root=null] - The element that is used as the viewport for checking visibility of the target elements.
 * @returns {void}
 */
export const useIntersection = (ref, selector, handler, options) => {
	useEffect(() => {
		const observers = [];

		if (ref.current && typeof IntersectionObserver === 'function') {
			/**
			 * Handles the intersection event by executing the provided handler function.
			 *
			 * @param {number} idx - The index of the observed element.
			 * @returns {function} - A function that receives an array of IntersectionObserverEntry objects.
			 */
			const handleIntersect = idx => entries => {
				handler(entries[0], idx);
			};

			ref.current.querySelectorAll(selector).forEach((node, idx) => {
				/**
				 * Creates a new IntersectionObserver instance and observes the target node.
				 *
				 * @type {IntersectionObserver}
				 */
				const observer = new IntersectionObserver(
					handleIntersect(idx),
					options
				);
				observer.observe(node);
				observers.push(observer);
			});

			/**
			 * Disconnects all the IntersectionObserver instances when the component is unmounted.
			 */
			return () => {
				observers.forEach(observer => {
					observer.disconnect();
				});
			};
		}
		return () => {};
		//eslint-disable-next-line
	}, [ref.current, options.threshold, options.rootMargin]);
};
