import { useEffect } from 'react';

const exitFullScreen = () => {
	if (!document) return;

	// Check if the document is currently in fullscreen mode
	const fullscreenElement =
		document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement ||
		document.msFullscreenElement;

	// If there's no fullscreen element, the document is not in fullscreen mode
	if (!fullscreenElement) return;

	if (document?.exitFullscreen) {
		document?.exitFullscreen();
	} else if (document['mozCancelFullScreen']) {
		document['mozCancelFullScreen']();
	} else if (document['webkitExitFullscreen']) {
		document['webkitExitFullscreen']();
	} else if (document['msExitFullscreen']) {
		document['msExitFullscreen']();
	}
};

export default function useFullScreen(
	elementOrElementId,
	showFullScreen,
	setShowFullScreen
) {
	useEffect(() => {
		let fullScreenElement =
			document['fullscreenElement'] ||
			document['webkitFullscreenElement'] ||
			document['mozFullScreenElement'] ||
			document['msFullscreenElement'];

		// exit full screen
		if (!showFullScreen) {
			if (fullScreenElement) {
				exitFullScreen();
				setShowFullScreen(false);
			}
			return;
		}

		// get the element to make full screen
		const element =
			typeof elementOrElementId === 'string'
				? document.getElementById(elementOrElementId)
				: elementOrElementId;

		// if there's another element currently full screen, exit first
		if (fullScreenElement && fullScreenElement !== element) {
			exitFullScreen();
			fullScreenElement = null;
			setShowFullScreen(false);
		}

		// if the current element is not already full screen, make it full screen
		if (!fullScreenElement) {
			console.log(element);
			if (element?.requestFullscreen) {
				element?.requestFullscreen();
			} else if (element['mozRequestFullScreen']) {
				element['mozRequestFullScreen']();
			} else if (element['webkitRequestFullscreen']) {
				// @ts-ignore
				element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (element['msRequestFullscreen']) {
				element['msRequestFullscreen']();
			}
		}

		// if full screen, the exit on unmount
		if (showFullScreen) {
			return exitFullScreen;
		}
		//eslint-disable-next-line
	}, [showFullScreen, elementOrElementId]);

	useEffect(() => {
		const handleChange = () => {
			const fullscreenElement =
				document.fullscreenElement ||
				document.mozFullScreenElement ||
				document.webkitFullscreenElement;

			if (fullscreenElement === null) {
				setShowFullScreen(false);
			}
		};
		document.addEventListener('fullscreenchange', handleChange);
		document.addEventListener('webkitfullscreenchange', handleChange);
		document.addEventListener('MSFullscreenChange', handleChange);
		return () => {
			document.removeEventListener('fullscreenchange', handleChange);
			document.removeEventListener(
				'webkitfullscreenchange',
				handleChange
			);
			document.removeEventListener('MSFullscreenChange', handleChange);
		};
		//eslint-disable-next-line
	}, []);
}
