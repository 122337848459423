import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
	&:focus {
		background-color: ${p => p.theme.colors.grey300};
		clip: auto !important;
		clip-path: none;
		color: ${p => p.theme.colors.grey900};
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: ${p => p.theme.spacing.desktop.xsmall};
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
	}
`;

export default function ScreenReaderText({
	className,
	OuterWrapper,
	children,
	...props
}) {
	if (OuterWrapper)
		return (
			<OuterWrapper>
				<Span className={className} {...props}>
					{children}
				</Span>
			</OuterWrapper>
		);

	return (
		<Span className={className} {...props}>
			{children}
		</Span>
	);
}
