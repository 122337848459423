import React, { createContext, useContext, useState, useRef } from 'react';

const PopupContext = createContext();
export function usePopup() {
	return useContext(PopupContext);
}

export default function PopupProvider({ children }) {
	const popupLinkRef = useRef(null);
	const popupTrigger = useRef('Click');

	const [activePopup, setActivePopup] = useState(null);
	const [images, setImages] = useState([]);

	function setCurrentImage(src) {
		if (!src) return;
		const newArray = [...images];
		newArray.forEach(function (img) {
			delete img.current;
		});
		const currentInArray = newArray.find(img => img?.file?.url === src);

		if (currentInArray) {
			currentInArray.url = src;
			currentInArray.current = true;
		}
		setImages(newArray);
	}

	return (
		<PopupContext.Provider
			value={{
				popupLinkRef,
				activePopup,
				setActivePopup,
				popupTrigger,
				images,
				setImages,
				imageCount: images?.length,
				setCurrentImage,
			}}>
			{children}
		</PopupContext.Provider>
	);
}
