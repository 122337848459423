export default function getOS() {
	const userAgent = navigator.userAgent || window.opera;
	let os = 'Unknown OS';

	if (/windows/i.test(userAgent)) {
		os = 'Windows';
	} else if (/android/i.test(userAgent)) {
		os = 'Android';
	} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		os = 'iOS';
	} else if (/mac/i.test(userAgent)) {
		os = 'Mac';
	} else if (/linux/i.test(userAgent)) {
		os = 'Linux';
	}

	return {
		name: os,
	};
}
