import React from 'react';
import styled from 'styled-components';
import { Script } from 'gatsby';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';

import useHasMounted from 'context/useHasMounted';
import { trackBackEnd } from 'context/AnalyticsProvider';
import Button from 'components/forms/Button';
import Heading from './heading';

const FacebookShareBtn = styled(Button)`
	margin: 0;
`;

export default function FacebookShare({
	title = '',
	headinglevel = 'h3',
	quote = '',
	entry,
	style,
}) {
	const hasMounted = useHasMounted();

	function openFbPopup() {
		if (!hasMounted || !window?.FB) return;
		window?.FB?.ui(
			{
				display: 'popup',
				method: 'feed',
				link: window?.location?.href,
				quote,
			},
			function (response) {
				if (response && !response.error_message) {
					console.log('Posting to facebook completed.');
					trackBackEnd('Article Shared', {
						label: 'Del på Facebook',
						source: 'Facebook',
						entry,
						context: 'Click',
					});
				} else {
					console.log('Error while posting to facebook.');
				}
			}
		);
	}

	if (!hasMounted || !window?.FB) return null;

	return (
		<>
			{title && (
				<Heading
					className="h4"
					headinglevel={headinglevel}
					style={{ marginBottom: '20px' }}>
					{title}
				</Heading>
			)}
			<FacebookShareBtn
				onClick={openFbPopup}
				title="Del på Facebook"
				style={style}
				small={true}
				icon={faFacebook}
				outlined="true">
				Del på Facebook
			</FacebookShareBtn>
		</>
	);
}

export function FacebookSDKScript() {
	return (
		<>
			<Script key="facebook-sdk-init">
				{`
				window.fbAsyncInit = function() {
					FB.init({
					appId            : ${process.env.GATSBY_FACEBOOK_APP_ID},
					autoLogAppEvents : true,
					xfbml            : true,
					version          : 'v17.0'
					});
				};`}
			</Script>
			<Script
				key="facebook-sdk"
				async
				defer
				crossorigin="anonymous"
				src="https://connect.facebook.net/en_US/sdk.js"
			/>
		</>
	);
}
