import { useEffect } from 'react';

/**
 * A React hook that adds a class to all 'figure' elements within a specified 'div' that have a previous sibling in the DOM.
 *
 * @param {string} divId - The id of the 'div' element to check for 'figure' elements.
 */
export const useCheckPreviousSibling = divId => {
	useEffect(() => {
		const divElement = document.getElementById(divId);
		if (!divElement) return;

		const figures = divElement.getElementsByTagName('figure');
		for (let i = 0; i < figures.length; i++) {
			if (figures[i].previousSibling) {
				figures[i].classList.add('has-previous-sibling');
			}
		}
	}, [divId]);
};
