import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import useHasMounted from 'context/useHasMounted';
import { track } from 'context/AnalyticsProvider';
import { getUrlCategory } from './content';

const StyledElement = styled.div``;
/**
 * Sanitize HTML content
 * @param {object} props - The component props
 * @param {string} props.html - The HTML content to sanitize
 * @param {string} props.className - The class name to add to the container
 * @param {object} props.props - The additional props to pass to the container
 * @returns {JSX.Element} - The sanitized HTML content
 */
export default function SanitizeHtml({ html, className, ...props }) {
	const hasMounted = useHasMounted();
	const divRef = useRef();
	const [cleanHtml, setCleanHtml] = useState(html);

	// Sanitize HTML content when it's loaded with DOMPurify
	useEffect(() => {
		if (!html || !hasMounted) return;

		const htmlContent =
			typeof html === 'string' ? html : html?.childMarkdownRemark?.html;
		if (!htmlContent) return;

		const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
			ADD_ATTR: ['target', 'rel'],
			ADD_TAGS: ['a'],
		});

		// Create a DOM parser and parse the sanitized HTML
		const parser = new DOMParser();
		const doc = parser.parseFromString(sanitizedHtml, 'text/html');

		// Get all the links in the parsed HTML
		const links = doc.querySelectorAll('a[href]');

		// Add the target="_blank" attribute to external links
		links.forEach(link => {
			const url = new URL(link.href);

			if (url.hostname !== window.location.hostname) {
				link.setAttribute('target', '_blank');
				link.setAttribute('rel', 'noopener noreferrer');
			}
		});

		// If the parsed HTML has a body, set the clean HTML
		if (doc?.body?.innerHTML) {
			setCleanHtml(doc?.body);
			return;
		}

		// Otherwise, serialize the parsed HTML back to a string
		const serializer = new XMLSerializer();
		const updatedHtml = serializer.serializeToString(doc);

		setCleanHtml(updatedHtml);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [html, hasMounted]);

	// Track link clicks
	useEffect(() => {
		const div = divRef.current;
		if (!div || !cleanHtml) return;

		/**
		 * Event handler for link clicks
		 * @param {object} e - The event object
		 * @returns {void}
		 */
		const eventHandler = e => {
			if (
				(e.type === 'click' ||
					(e.type === 'keydown' && e.key === 'Enter')) &&
				e.target.tagName === 'A'
			) {
				trackLinkClick(e);
			}
		};

		// Add event listeners to the container
		div.addEventListener('click', eventHandler);
		div.addEventListener('keydown', eventHandler);

		// Remove event listeners when the component is unmounted
		return () => {
			div.removeEventListener('click', eventHandler);
			div.removeEventListener('keydown', eventHandler);
		};
	}, [cleanHtml]);

	// If the HTML content is empty, return null
	if (!cleanHtml) return null;

	return (
		<StyledElement
			as={props?.type || 'div'}
			ref={divRef}
			className={`sanitized${className ? ' ' + className : ''}`}
			dangerouslySetInnerHTML={{
				__html:
					cleanHtml?.innerHTML ||
					(hasMounted &&
						DOMPurify.sanitize(
							typeof html === 'string'
								? html
								: html?.childMarkdownRemark?.html
						)) ||
					'',
			}}
			{...props}
		/>
	);
}

/**
 * Track link clicks
 * @param {object} event - The event object
 * @param {string} label - The label to track (default is the innerText of the target)
 * @param {string} category - The category to track (default is the pathname)
 * @param {string} href - The href to track (default is the origin)
 * @returns {void}
 */
function trackLinkClick(event, label, href) {
	if (!event) return;

	track('Button Clicked', {
		label: label || event?.target?.innerText,
		category:
			(window?.location?.pathname &&
				getUrlCategory(window?.location?.pathname)) ||
			'',
		href: href || event?.target?.href,
	});
}
