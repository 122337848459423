import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(
	process.env.GATSBY_ALGOLIA_APP_ID,
	process.env.GATSBY_ALGOLIA_API_KEY
);

// const searchClient = {
// 	...algoliaClient,
// 	search: requests => {
// 		console.log(
// 			requests,
// 			requests.every(
// 				({ params, indexName }) =>
// 					!params.query &&
// 					indexName !== 'prod_NTENO_blogposts' &&
// 					indexName !== 'prod_NTENO_products'
// 			)
// 		);
// 		if (
// 			requests.every(
// 				({ params, indexName }) =>
// 					!params.query &&
// 					indexName !== 'prod_NTENO_blogposts' &&
// 					indexName !== 'prod_NTENO_products'
// 			)
// 		) {
// 			return;
// 		}

// 		return algoliaClient.search(requests);
// 	},
// };

export default algoliaClient;
