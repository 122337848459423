import React from 'react';
import {
	ApolloProvider,
	from,
	ApolloClient,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import setupHeaders from 'libs/setup-headers';

const httpLink = new HttpLink({
	uri: process.env.GATSBY_API_BASE_URL + 'graphql',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
		console.log(`[GraphQL error]: Message: ${graphQLErrors}, `);

	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const contextLink = setContext(async (_, { headers = {} }) => {
	return {
		headers: setupHeaders(headers),
	};
});

const client = new ApolloClient({
	link: from([contextLink, errorLink, httpLink]),
	cache: new InMemoryCache(),
	ssrMode: true,
});

export default function GraphqlProvider({ children }) {
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
